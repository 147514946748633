import { Button } from "@material-tailwind/react";
import React from "react";
import {app_url} from "../index"

export function NotFoundLogged() {  
  return (
    <div className="h-[calc(80vh-2rem)] w-full flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg w-96 p-6">
        <h1 className="text-2xl font-semibold mb-4">Not Found</h1>
        <p className="text-gray-600 text-sm mb-6">
          The specified resource was not found
        </p>
        <div className="flex justify-between">
          <div className="w-1/2 p-4">
            <Button variant="gradient" fullWidth onClick={() => {
                window.location.href = `${app_url}dashboard`;
              }}>
              Home Page
            </Button>
          </div>

          <div className="w-1/2 p-4">
            <Button
              variant="gradient"
              color="light-green"
              fullWidth
              onClick={() => {
                window.location.href = "https://www.indexing.co";
              }}
            >
              Indexing Co
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export function NotFoundUnlogged() {  
  const handleLogout = () => {
    sessionStorage.removeItem("valid");
    sessionStorage.removeItem("user");
    window.location.href = `${app_url}`;  };
  return (
    <div className="h-[calc(80vh-2rem)] w-full flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg w-96 p-6">
        <h1 className="text-2xl font-semibold mb-4">Not Found</h1>
        <p className="text-gray-600 text-sm mb-6">
          The specified resource was not found
        </p>
        <div className="flex justify-between">
          <div className="w-1/2 p-4">
            <Button variant="gradient" fullWidth onClick={handleLogout}>
              Home Page
            </Button>
          </div>

          <div className="w-1/2 p-4">
            <Button
              variant="gradient"
              color="light-green"
              fullWidth
              onClick={() => {
                window.location.href = "https://www.indexing.co";
              }}
            >
              Indexing Co
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
