import React, { useEffect, useState } from "react";
import { api_url } from "../index";
import axios from "axios";

export default function ScriptSelector(props) {
  const { onScript, onClose } = props;
  const [scriptList, setScriptList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchScripts = async () => {
      const options = {
        method: "GET",
        url: `${api_url}scripts/all`,
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
        },
      };

      try {
        const response = await axios.request(options);
        setScriptList(response.data);
      } catch (error) {
        console.log(error);
        setScriptList([]);
      }
    };

    fetchScripts();
  }, []);

  const handleScript = (script) => {
    onScript(script, null, 2);
    onClose();
  };

  const filteredScriptList = scriptList.filter(
    (script) =>
      script.scriptName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      script.indexer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div className="bg-white p-6 rounded-lg shadow-md h-3/4 w-1/2">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Select a Script</h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="relative flex items-center">
            <input
              type="text"
              placeholder="Search Config"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-green-200"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-5 w-5 absolute left-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>

          </div>
        <div
          className="space-y-4 mt-4 overflow-y-auto"
          style={{ maxHeight: "75%" }}
        >
          {filteredScriptList.map((script) => (
            <button
              key={script}
              onClick={() => handleScript(script)}
              className="block w-full p-3 bg-green-300 text-white rounded-md hover:bg-green-500 focus:outline-none"
            >
              {script.indexer} - {script.scriptName}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
