import React from "react";
import { useParams } from "react-router-dom";
import RunBackfillView from "./run-backfill";
import PacemakerBackfillView from "./pacemaker-backfill";
import QueuePacemakerView from "./queue-backfill";

export default function IndexScriptsView() {
    const { scriptsAction } = useParams();

  return (
    <div>
    {scriptsAction === "backfill" && <RunBackfillView/>}
    {scriptsAction === "pacemakerbackfill" && <PacemakerBackfillView/>}
    {scriptsAction === "queuepacemaker" && <QueuePacemakerView/>}
    </div>
  );
}
