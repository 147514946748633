import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator
import { api_url } from "../../index";

interface NetworkConnection {
  network: string;
  host: string;
  rate_limit: number;
  connection_type: string;
  enabled: boolean;
  id?: string; // Add optional id field to store UUID
}

export default function NetworksConnections() {
  const [connections, setConnections] = useState<NetworkConnection[]>([]);
  const [editing, setEditing] = useState<string | null>(null); // Track which row is being edited
  const [changesMade, setChangesMade] = useState(false); // Track if changes were made
  const [searchQuery, setSearchQuery] = useState(""); // Track search input

  useEffect(() => {
    fetchConnectionsData();
  }, []);

  const fetchConnectionsData = async () => {
    const options = {
      method: "GET",
      url: `${api_url}oscar/networksconnections`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      const dataWithId = response.data.map((conn: NetworkConnection) => ({
        ...conn,
        id: uuidv4(), // Generate a unique ID for each connection
      }));
      setConnections(dataWithId);
    } catch (error) {
      console.error("Error fetching network connections data", error);
    }
  };

  const handleSaveChanges = async (connection: NetworkConnection) => {
    const payload = {
      message: {
        data: JSON.stringify({
          network_key: connection.network,
          host: connection.host,
          rate_limit: connection.rate_limit,
          connection_type: connection.connection_type,
        }),
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}oscar/setnetworkconnection`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: payload,
    };

    try {
      await axios.request(options);
      setEditing(null); // Exit edit mode after saving
      setChangesMade(false); // Reset changes tracker
      fetchConnectionsData();
    } catch (error) {
      console.error("Error saving network connection", error);
      setEditing(null); // Exit edit mode after saving
      setChangesMade(false); // Reset changes tracker
      fetchConnectionsData();
    }
  };

  const handleRowClick = (id: string) => {
    setEditing(id);
    setChangesMade(false); // Reset change tracker when entering edit mode
  };

  const handleInputChange = (id: string, field: string, value: any) => {
    setConnections(
      connections.map((conn) =>
        conn.id === id ? { ...conn, [field]: value } : conn
      )
    );
    setChangesMade(true); // Track that changes have been made
  };

  // Filter connections based on search query
  const filteredConnections = connections.filter(
    (conn) =>
      conn.network.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conn.host.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conn.connection_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      conn.rate_limit.toString().includes(searchQuery)
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="text-3xl font-semibold">Networks Connections</h1>
        <input
          type="text"
          placeholder="Search Networks"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-1/3 p-2 pl-4 border border-gray-300 rounded"
        />
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2">Network</th>
              <th className="py-2">Host</th>
              <th className="py-2">Connection Type</th>
              <th className="py-2">Rate Limit</th>
              <th className="py-2">Enabled</th>
            </tr>
          </thead>
          <tbody>
            {filteredConnections.map((conn) => (
              <tr
                key={conn.id} // Use UUID as key
                className={`hover:bg-gray-100 cursor-pointer ${
                  editing === conn.id ? "bg-gray-200" : ""
                }`}
                onClick={() => handleRowClick(conn.id)}
              >
                <td className="py-2 text-center">{conn.network}</td>
                <td className="py-2 text-center max-w-xs truncate">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        type="text"
                        value={conn.host}
                        onChange={(e) =>
                          handleInputChange(conn.id, "host", e.target.value)
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                  ) : (
                    <span title={conn.host}>{conn.host}</span> // Show full host on hover
                  )}
                </td>
                <td className="py-2 text-center">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        type="text"
                        value={conn.connection_type}
                        onChange={(e) =>
                          handleInputChange(conn.id, "connection_type", e.target.value)
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                  ) : (
                    conn.connection_type
                  )}
                </td>
                <td className="py-2 text-center">
                  {editing === conn.id ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <input
                        type="number"
                        value={conn.rate_limit}
                        onChange={(e) =>
                          handleInputChange(conn.id, "rate_limit", parseInt(e.target.value))
                        }
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                  ) : (
                    conn.rate_limit
                  )}
                </td>
                <td className="py-2 text-center">
                  {conn.enabled ? "Yes" : "No"}
                </td>
                {editing === conn.id && (
                  <td className="py-2 text-center">
                    <button
                      className={`px-4 py-2 bg-green-500 text-white rounded ${
                        !changesMade ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => handleSaveChanges(conn)}
                      disabled={!changesMade}
                    >
                      Save
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}