import { Alert, Typography } from "@material-tailwind/react";
import React, {useEffect} from "react";

function IconGood() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
function IconBad() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function AlertDone(props) {
  const { status, message, open, onClose } = props;
  const success = status === 200 ? true : false;
  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [open, onClose]);
  return (
    <>
      {success && (
        <Alert
          open={open}
          className="fixed bottom-0 left-0 right-0 w-full mx-auto"
          color ='light-green'
          icon={<IconGood />}
          onClose={() => onClose()}
        >
          <Typography variant="h5" color="white">
            Success
          </Typography>
          <Typography color="white" className="mt-2 font-normal">
            {message}
          </Typography>
        </Alert>
      )}
      {!success && (
        <Alert
          open={open}
          className="fixed bottom-0 left-0 right-0 w-full-md mx-auto"
          color ='red'
          icon={<IconBad />}
          onClose={() => onClose()}
        >
          <Typography variant="h5" color="white">
            Error {status}
          </Typography>
          <Typography color="white" className="mt-2 font-normal">
            There's an error, with the following stack trace {message}
          </Typography>
        </Alert>
      )}
    </>
  );
}
