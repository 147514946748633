import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface LastSeenData {
  network: string;
  instance_id_short: string;
  max_beat: number;
  last_seen: string;
  pending: string;
  waiting: string;
}

export default function LastSeen() {
  const [data, setData] = useState<LastSeenData[]>([]);
  const [searchQuery, setSearchQuery] = useState(""); // Track search input
  const [loading, setLoading] = useState(false); // To show loading status during refresh

  useEffect(() => {
    fetchLastSeenData();
  }, []);

  const fetchLastSeenData = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `${api_url}oscar/lastseen`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching last seen data", error);
    } finally {
      setLoading(false);
    }
  };

  // Filter data based on search query
  const filteredData = data.filter(
    (item) =>
      item.network.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.instance_id_short
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.max_beat.toString().includes(searchQuery) ||
      item.last_seen.toLowerCase().includes(searchQuery) ||
      item.pending.includes(searchQuery) ||
      item.waiting.includes(searchQuery)
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="text-3xl font-semibold">Last Seen</h1>
        <div className="flex justify-end items-center w-1/2">
          <button
            onClick={fetchLastSeenData}
            className={`px-4 py-2 mr-4 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search Network"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-1/2 p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2">Network</th>
              <th className="py-2">Instance ID</th>
              <th className="py-2">Max Beat</th>
              <th className="py-2">Last Seen</th>
              <th className="py-2">Pending</th>
              <th className="py-2">Waiting</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-2 text-center">{item.network}</td>
                  <td className="py-2 text-center">{item.instance_id_short}</td>
                  <td className="py-2 text-center">{item.max_beat}</td>
                  <td className="py-2 text-center">
                    {new Date(item.last_seen).toLocaleString()}
                  </td>
                  <td className="py-2 text-center">{item.pending}</td>
                  <td className="py-2 text-center">{item.waiting}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
