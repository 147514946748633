import React from "react";
import { Card } from "@material-tailwind/react";
import Footer from "../components/footer";

export default function MainView() {
  
  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="text-center mt-4">
        <h1 className="text-3xl font-semibold mb-6">
          Welcome to Indexing Console
        </h1>
      </div>

      <div className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4">
        <h2 className="text-xl font-semibold">Tutorials</h2>
        <p className="text-lg">
          Here is some content for the Tutorials section.
        </p>
      </div>

      <div className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4">
        <h2 className="text-xl font-semibold">Documentation</h2>
        <p className="text-lg">
          Here is some content for the Documentation section.
        </p>
      </div>

      <div className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4">
        <h2 className="text-xl font-semibold">About</h2>
        <p className="text-lg">Here is some content for the About section.</p>
      </div>

      <div className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4">
        <h2 className="text-xl font-semibold">New Releases</h2>
        <p className="text-lg">
          Here is some content for the new releases section.
        </p>
      </div>
      <a
        href="https://blog.indexing.co"
        className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4"
        target="_blank"
        rel="noopener noreferrer"
      >
        <h2 className="text-xl font-semibold">Last Updates</h2>
        <p className="text-lg">
          Here is the acces to our Indexing Blog, with the latest updates.
        </p>
      </a>

      <div className="section mb-6 flex-1 border border-t-0 border-r-0 border-b-0 border-l-4 border-gray-400 p-4">
        <h2 className="text-xl font-semibold">Other Products</h2>
        <ul className="list-disc pl-5 text-lg">
          <li className="mb-2">
            <a
              href="https://www.indexing.co"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Indexing Web Page
            </a>
          </li>
          <li className="mb-2">
            <a
              href="https://www.mirrormirror.page"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              Mirror Mirror
            </a>
          </li>
          <li className="mb-2">
            <a
              href="https://www.whatsmynameagain.xyz"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              What's My Name Again?
            </a>
          </li>
        </ul>
      </div>
      <Footer/>
    </Card>
  );
}
