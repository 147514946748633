import { Button } from "@material-tailwind/react";
import React from "react";
import LoginButton from "../components/login-button";

export default function Login() {
  return (
    <div className="h-[calc(80vh-2rem)] w-full flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg w-96 p-6">
        <h1 className="text-2xl font-semibold mb-4">
          Welcome to Indexing Co Services
        </h1>
        <p className="text-gray-600 text-sm mb-6">
          For the following application, you must be logged in. Click the login
          button to log in; otherwise, return to the Indexing homepage.
        </p>
        <div className="flex justify-between">
          <div className="w-1/2 p-4">
            <LoginButton />
          </div>

          <div className="w-1/2 p-4">
            <Button
              variant="gradient"
              color = "light-green"
              fullWidth
              onClick={() => {
                window.location.href = "https://www.indexing.co";
              }}
            >
              Indexing Co
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
