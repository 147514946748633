import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
} from "@material-tailwind/react";
import {
  CommandLineIcon,
  CogIcon,
  UserGroupIcon,
  AdjustmentsVerticalIcon,
  DocumentMagnifyingGlassIcon,
  QueueListIcon,
  PowerIcon,
  UserCircleIcon,
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  WrenchIcon,
  UserPlusIcon,
  UsersIcon,
  FlagIcon,
  ClockIcon,
  CalendarIcon,
  PresentationChartLineIcon,
  BriefcaseIcon,
  AdjustmentsHorizontalIcon,
  HeartIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import LogoutButton from "./logout-button";
import { app_url } from "../index";

export default function Sidebar(props) {
  const [open, setOpen] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const sections = [
    {
      id: 1,
      title: "Actions",
      icon: CommandLineIcon,
      items: [
        {
          label: "Run Backfill",
          href: `${app_url}scripts/backfill`,
          icon: ArrowRightCircleIcon,
        },
        {
          label: "Run Pacemaker Backfill",
          href: `${app_url}scripts/pacemakerbackfill`,
          icon: ArrowRightCircleIcon,
        },
        {
          label: "Queue Pacemaker",
          href: `${app_url}scripts/queuepacemaker`,
          icon: ArrowRightCircleIcon,
        },
      ],
    },
    {
      id: 2,
      title: "Configs",
      icon: CogIcon,
      items: [
        {
          label: "Add Config",
          href: `${app_url}configs/save`,
          icon: WrenchIcon,
        },
        {
          label: "Test Config",
          href: `${app_url}configs/test`,
          icon: WrenchIcon,
        },
        {
          label: "Delete Config",
          href: `${app_url}configs/delete`,
          icon: WrenchIcon,
        },
      ],
    },
    {
      id: 3,
      title: "Customers",
      icon: UserGroupIcon,
      items: [
        {
          label: "Add Customer",
          href: `${app_url}customers/save`,
          icon: UserPlusIcon,
        },
        {
          label: "Get Customers",
          href: `${app_url}customers/all`,
          icon: UsersIcon,
        },
      ],
    },
    {
      id: 4,
      title: "Utils",
      icon: AdjustmentsVerticalIcon,
      items: [
        {
          label: "Add Custom Script",
          href: `${app_url}utils/addscript`,
          icon: WrenchIcon,
        },
        {
          label: "Feature Flag",
          href: `${app_url}utils/flags`,
          icon: FlagIcon,
        },
      ],
    },
    {
      id: 5,
      title: "Logs",
      icon: DocumentMagnifyingGlassIcon,
      items: [
        {
          label: "Live Tail",
          href: `${app_url}logs/live`,
          icon: ClockIcon,
        },
        {
          label: "Historical Logs",
          href: `${app_url}logs/time`,
          icon: CalendarIcon,
        },
      ],
    },
    {
      id: 6,
      title: "Rosie",
      icon: QueueListIcon,
      items: [
        {
          label: "Metrics",
          href: `${app_url}rosie/charts`,
          icon: PresentationChartLineIcon,
        },
        {
          label: "Historical Jobs",
          href: `${app_url}rosie/jobs`,
          icon: BriefcaseIcon,
        },
      ],
    },
    {
      id: 7,
      title: "Testing",
      icon: AdjustmentsHorizontalIcon,
      items: [
        {
          label: "RPC",
          href: `${app_url}testing/metrics`,
          icon: PresentationChartLineIcon,
        },
      ],
    },
    {
      id: 8,
      title: "Oscar",
      icon: HeartIcon,
      items: [
        {
          label: "Networks",
          href: `${app_url}oscar/networks`,
          icon: ListBulletIcon,
        },
        {
          label: "Networks Connections",
          href: `${app_url}oscar/networksconnections`,
          icon: ListBulletIcon,
        },
        {
          label: "Last Seen",
          href: `${app_url}oscar/lastseen`,
          icon: QueueListIcon,
        },
      ],
    },
  ];

  const filteredSections = sections
    .filter((section) =>
      section.items.some((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase())
      )
    )
    .map((section) => ({
      ...section,
      items: section.items.filter((item) =>
        item.label.toLowerCase().includes(searchText.toLowerCase())
      ),
    }));

  return (
    <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="mb-2 flex items-center gap-4 p-4">
        <img
          src={`${process.env.PUBLIC_URL}/indexing-logo.png`}
          alt="brand"
          className="h-8 w-8"
        />
        <Typography variant="h5" color="blue-gray">
          Indexing Console
        </Typography>
      </div>
      <Input
        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
        label="Search"
        crossOrigin={undefined}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <List>
        {filteredSections.map((section) => (
          <Accordion key={section.id} open={open === section.id}>
            <ListItem className="p-0" selected={open === section.id}>
              <AccordionHeader
                onClick={() => handleOpen(section.id)}
                className="border-b-0 p-3"
              >
                <ListItemPrefix>
                  <section.icon className="h-5 w-5" />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-normal">
                  {section.title}
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                {section.items.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={() => (window.location.href = item.href)}
                  >
                    <ListItemPrefix>
                      <item.icon className="h-3 w-5" />
                    </ListItemPrefix>
                    {item.label}
                  </ListItem>
                ))}
              </List>
            </AccordionBody>
          </Accordion>
        ))}
      </List>
      <hr className="my-2 border-blue-gray-50" />
      <ListItem>
        <ListItemPrefix>
          <UserCircleIcon className="h-5 w-5" />
        </ListItemPrefix>
        User: {props.name}
      </ListItem>
      <ListItem>
        <ListItemPrefix>
          <PowerIcon className="h-5 w-5" />
        </ListItemPrefix>
        <LogoutButton />
      </ListItem>
    </Card>
  );
}
