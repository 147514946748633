import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import { api_url } from "../../index";
import ConfigSelector from "../../components/configselector";
import { TestResult } from "../../components/TestResult";

export default function TestConfigView() {
  const [showFilterInput, setShowFilterInput] = useState(false);
  const [showSaveInput, setShowSaveInput] = useState(false);
  const [showLimitInput, setShowLimitInput] = useState(false);
  const [showSourceInput, setShowSourceInput] = useState(false);
  const [showExec, setShowExec] = useState(false);
  const [showTestResult, setTestResult] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [message2, setMessage2] = useState("");
  const [status, setStatus] = useState(400);
  const [config, setConfig] = useState(undefined);
  const [selectConfig, setSelectConfig] = useState(false);

  const handleCloseAlert = () => {
    setShowExec(false);
  };
  const handleTestResult = () => {
    setTestResult(false);
  };
  const handleConfig = (config) => {
    setConfig(config);
  };
  const handleSelectConfig = () => {
    setSelectConfig(!selectConfig);
  };
  const cleanAndFormatData = (data) => {
    const cleanedData = data.replace(/^test finished succesfully:\s+/i, "");
    const formattedData = cleanedData
      .replace(/\[debug\]\[.*?\]/g, "")
      .replace(/\[[0-9]+m/g, "")
      .replace(/(\s)(?=[A-Z])/g, "")
      .replace(/[^\x20-\x7E\n]/g, ""); // Include \n to preserve line breaks
    return formattedData;
  };
  const handleform = async (e) => {
    e.preventDefault();
    var config = JSON.stringify(e.target.elements.config.value);
    var msg = `"config":${config} `;
    if (showFilterInput === true) {
      msg += `, "filter": ${JSON.stringify(e.target.elements.filter.value)}`;
    }
    if (showSaveInput === true) {
      msg += `, "save": "true"`;
    }
    if (showLimitInput === true) {
      msg += `, "limit": ${JSON.stringify(e.target.elements.limit.value)},`;
    }
    if (showSourceInput === true) {
      msg += `, "source": ${JSON.stringify(e.target.elements.source.value)}`;
    }
    const message = {
      message: {
        data: `{${msg}}\n`,
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}configs/test`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage("test finished succesfully");
        setStatus(response.status);
        setShowExec(true);
        setMessage2(cleanAndFormatData(response.data));
        setTestResult(true);
      })
      .catch(function (error) {
        setMessage(`${error.response.data}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };
  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      {showTestResult && (
        <TestResult message={message2} onClose={handleTestResult} />
      )}
      <form onSubmit={handleform} className="flex flex-col h-full w-full">
        {selectConfig && (
          <ConfigSelector
            onConfig={handleConfig}
            onClose={handleSelectConfig}
          />
        )}
        <h1 className="text-3xl font-semibold my-6 text-center">Test Config</h1>
        <div className="flex">
          <div className="w-full md:w-1/2 p-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="config"
            >
              Config
            </label>
            <textarea
              className="resize-none appearance-none block w-full h-[40vh] bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="config"
              placeholder="Config"
              value={config}
              onChange={(e) => setConfig(e.target.value)}
              rows={12}
            ></textarea>
            <p className="text-green-200 text-xs italic">
              Please fill out this field.
            </p>
          </div>

          <div className="w-full md:w-1/2 p-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="options"
            >
              Options
            </label>
            <div className="space-y-2">
              <div>
                <input
                  type="checkbox"
                  id="filter"
                  onChange={() => setShowFilterInput(!showFilterInput)}
                />
                <label className="ml-2" htmlFor="filter">
                  Filter
                </label>
                {showFilterInput && (
                  <textarea
                    className="appearance-none block w-full h-[20vh] bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    rows={5}
                    placeholder="Filter"
                  />
                )}
              </div>

              <div>
                <input
                  type="checkbox"
                  id="save"
                  onChange={() => setShowSaveInput(!showSaveInput)}
                />
                <label className="ml-2" htmlFor="save">
                  Save
                </label>
              </div>

              <div>
                <input
                  type="checkbox"
                  id="limit"
                  onChange={() => setShowLimitInput(!showLimitInput)}
                />
                <label className="ml-2" htmlFor="limit">
                  Limit
                </label>
                {showLimitInput && (
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Limit"
                  />
                )}
              </div>

              <div>
                <input
                  type="checkbox"
                  id="source"
                  onChange={() => setShowSourceInput(!showSourceInput)}
                />
                <label className="ml-2" htmlFor="source">
                  Source
                </label>
                {showSourceInput && (
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Source"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <div className="mr-2">
            <Button
              variant="gradient"
              color="orange"
              onClick={handleSelectConfig}
            >
              Select Config
            </Button>
          </div>
          <div className="ml-2">
            <Button variant="gradient" color="light-green" type="submit">
              Submit Config
            </Button>
          </div>
        </div>
      </form>
      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}
