import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import { api_url } from "../../index";
import ScriptSelector from "../../components/scriptSelector";

export default function SaveCustomScriptView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be executed");
  const [status, setStatus] = useState(400);
  const [script, setScript] = useState(undefined);
  const [indexer, setIndexer] = useState("");
  const [scriptName, setScriptName] = useState("");
  const [selectScript, setSelectScript] = useState(false);

  
  const handleSelectScript = () => {
    setSelectScript(!selectScript);
  };

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const handleScript = (script) => {
    setScript(script.script);
    setIndexer(script.indexer);
    setScriptName(script.scriptName);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const message = {
      message: {
        data: JSON.stringify({
          indexer: indexer,
          scriptName: scriptName,
          script: script,
        }),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}scripts/save`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    try {
      const response = await axios.request(options);
      setMessage(response.data);
      setStatus(response.status);
      setShowExec(true);
    } catch (error) {
      setMessage(`${error.response.data}`);
      setStatus(error.response.status);
      setShowExec(true);
    }
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto flex items-center">
      <form onSubmit={handleForm} className="flex flex-col w-full max-w-lg">
      {selectScript && (
          <ScriptSelector
            onScript={handleScript}
            onClose={handleSelectScript}
          />
        )}
        <h1 className="text-3xl font-semibold my-6 text-center">Add Script</h1>
        <div className="flex justify-between">
          <div className="w-1/2 pr-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="indexer"
            >
              Indexer
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="indexer"
              type="text"
              placeholder="Indexer"
              value={indexer}
              onChange={(e) => setIndexer(e.target.value)}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="scriptName"
            >
              Script Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="scriptName"
              type="text"
              placeholder="Script Name"
              value={scriptName}
              onChange={(e) => setScriptName(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 w-full justify-center items-center">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="script"
            >
              Script
            </label>
            <textarea
              className="resize-none appearance-none block w-full h-[40vh] bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="script"
              placeholder="Script"
              value={script}
              onChange={(e) => setScript(e.target.value)}
              rows={12}
            ></textarea>
            <p className="text-green-200 text-xs italic">
              Please fill out this field.
            </p>
          </div>
        </div>
        <div className="flex justify-center mt-4">
        <div className="mr-2">
            <Button
              variant="gradient"
              color="orange"
              onClick={handleSelectScript}
            >
              Select Script
            </Button>
          </div>
          <div className="ml-2">
            <Button variant="gradient" color="light-green" type="submit">
              Submit Script
            </Button>
          </div>
        </div>
      </form>
      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}
