import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface NetworkData {
  key: string;
  pacemaker: string;
  safe_beat_delay: number;
  beat_time_ms: number;
  enabled: boolean;
  is_prod: boolean;
  instance_id: string | null;
  network_params: any | null;
  workers: number;
}

export default function OscarNetworks() {
  const [networks, setNetworks] = useState<NetworkData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchNetworkData();
  }, []);

  const fetchNetworkData = async () => {
    const options = {
      method: "GET",
      url: `${api_url}oscar/networks`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      setNetworks(response.data);
    } catch (error) {
      console.error("Error fetching network data", error);
    }
  };

  const handleToggleEnabled = async (
    networkKey: string,
    currentValue: boolean
  ) => {
    const newValue = !currentValue;
    const payload = {
      message: {
        data: JSON.stringify({ network_key: networkKey, value: newValue }),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}oscar/setnetwork`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: payload,
    };

    try {
      await axios.request(options);
      setNetworks(
        networks.map((network) =>
          network.key === networkKey
            ? { ...network, enabled: newValue }
            : network
        )
      );
    } catch (error) {
      console.error("Error toggling network", error);
    }
  };

  const filteredNetworks = networks.filter((network) =>
    network.key.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Count the number of active (enabled) networks
  const activeNetworksCount = networks.filter((network) => network.enabled)
    .length;

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        {/* Update the title to include the active connections count */}
        <h1 className="text-3xl font-semibold">
          Oscar Networks - {activeNetworksCount} Active Chains 
        </h1>
        <input
          type="text"
          placeholder="Search Networks"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-1/3 p-2 pl-4 border border-gray-300 rounded"
        />
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2">Key</th>
              <th className="py-2">Pacemaker</th>
              <th className="py-2">Safe Beat Delay</th>
              <th className="py-2">Beat Time (ms)</th>
              <th className="py-2">Enabled</th>
              <th className="py-2">Production</th>
              <th className="py-2">Workers</th>
              <th className="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredNetworks.map((network) => (
              <tr key={network.key} className="hover:bg-gray-100">
                <td className="py-2 text-center">{network.key}</td>
                <td className="py-2 text-center">{network.pacemaker}</td>
                <td className="py-2 text-center">{network.safe_beat_delay}</td>
                <td className="py-2 text-center">{network.beat_time_ms}</td>
                <td className="py-2 text-center">
                  {network.enabled ? "Yes" : "No"}
                </td>
                <td className="py-2 text-center">
                  {network.is_prod ? "Yes" : "No"}
                </td>
                <td className="py-2 text-center">{network.workers}</td>
                <td className="py-2 text-center">
                  <button
                    className={`px-4 py-2 rounded ${
                      network.enabled ? "bg-red-500" : "bg-green-500"
                    } text-white`}
                    onClick={() =>
                      handleToggleEnabled(network.key, network.enabled)
                    }
                  >
                    {network.enabled ? "Disable" : "Enable"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}