import React from "react";
import { useParams } from "react-router-dom";
import SaveCustomerView from "./add-customer";
import AllCustomersView from "./all-customers";

export default function IndexCustomersView() {
    const { customerAction } = useParams();

  return (
    <div>
    {customerAction === "save" && <SaveCustomerView/>}
    {customerAction === "all" && <AllCustomersView/>}
    </div>
  );
}
