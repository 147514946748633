import React, { useState } from "react";
import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import { AlertDone } from "../../components/request-response";
import { api_url } from "../../index";

export default function SaveCustomerView() {
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be excecuted");
  const [status, setStatus] = useState(400);
  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const handleform = async (e) => {
    e.preventDefault();
    var indexer = JSON.stringify(e.target.elements.indexer.value);
    const message = {
      message: {
        data: `{"indexer": ${indexer}}\n`,
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}customers/save`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setMessage(response.data);
        setStatus(response.status);
        setShowExec(true);
      })
      .catch(function (error) {
        setMessage(`${error.response.data}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };
  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto flex items-center">
      <form onSubmit={handleform} className="flex flex-col w-full max-w-lg">
        <h1 className="text-3xl font-semibold my-6 text-center">Add Customer</h1>
        <div className="w-full justify-center items-center">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="config"
          >
            Indexer
          </label>
          <input
            className="resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-green-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="indexer"
            placeholder="Indexer"
          ></input>
          <p className="text-green-200 text-xs italic">
            Please fill out this field.
          </p>
        </div>
        <div className="flex justify-center mt-4">
          <div className="ml-2">
            <Button variant="gradient" color="light-green" type="submit">
              Save Customer
            </Button>
          </div>
        </div>
      </form>
      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}
