import React from "react";
import { useParams } from "react-router-dom";
import LiveTailView from "./live-tail";
import HistoricalView from "./historical";

export default function IndexLogsView() {
    const { logsAction } = useParams();

  return (
    <div>
    {logsAction === "live" && <LiveTailView/>}
    {logsAction === "time" && <HistoricalView/>}
    </div>
  );
}
