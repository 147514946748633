import { Button } from "@material-tailwind/react";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {app_url} from "../index";

export default function NotAuthorized() {
  const { logout } = useAuth0();
  const handleLogout = () => {
    sessionStorage.removeItem("valid");
    sessionStorage.removeItem("user");
    logout({ logoutParams: { returnTo: app_url } });
  };
  return (
    <div className="h-[calc(80vh-2rem)] w-full flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg w-96 p-6">
        <h1 className="text-2xl font-semibold mb-4">User Not Authorized</h1>
        <p className="text-gray-600 text-sm mb-6">
          You are not authorized to access the resources please contact the
          Indexing Co team if you think there might be an error, try to login
          again, remember to validate your mail.
        </p>
        <div className="flex justify-between">
          <div className="w-1/2 p-4">
            <Button variant="gradient" fullWidth onClick={handleLogout}>
              LogIn
            </Button>
          </div>

          <div className="w-1/2 p-4">
            <Button
              variant="gradient"
              color="light-green"
              fullWidth
              onClick={() => {
                window.location.href = "https://www.indexing.co";
              }}
            >
              Indexing Co
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
