import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import { Auth0Provider } from "@auth0/auth0-react";
export const app_url = true ? "https://admin.indexing.co/": "https://localhost:3000/";
export const api_url = true? "https://app.indexing.co/manager/": "http://35.222.180.24:8080/manager/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-gb4xe4bm6ke8b2ns.us.auth0.com"
      clientId="EPbQdo5Wjf9ItHo0PhVSLxJyjBoMy6f9"
      authorizationParams={{
        redirect_uri: `${app_url}dashboard`,
        audience: "https://app.indexing.co/",
        scope: "openid profile email",
      }}
    >
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
