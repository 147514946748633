import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {app_url} from "../index";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    sessionStorage.removeItem("valid");
    sessionStorage.removeItem("user");
    logout({ logoutParams: { returnTo: app_url } });
  };
  return <button onClick={handleLogout}>Log Out</button>;
};

export default LogoutButton;
