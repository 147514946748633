import { Typography, Button } from "@material-tailwind/react";
import React, { useEffect } from "react";

export function TestResult(props) {
  const { message, onClose } = props;
  useEffect(() => {
    if (message) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 30000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [message, onClose]);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-80 backdrop-blur-sm">
      <div className="bg-white w-2/3 h-2/3 rounded-lg shadow-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h6">Results</Typography>
          <Button onClick={onClose} color="light-green">
            Close
          </Button>
        </div>
        <div className="overflow-auto h-4/5 whitespace-pre-line">{message}</div>
      </div>
    </div>
  );
}
