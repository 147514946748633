export const pacemakers = [
  "arbitrum",
  "arweave",
  "avalanche",
  "base",
  "base_goerli",
  "base_sepolia",
  "berachain_testnet",
  "bitcoin",
  "blast",
  "bsc",
  "celo",
  "degen",
  "dogecoin",
  "ethereum",
  "eth_goerli",
  "eth_holesky",
  "eth_sepolia",
  "farcaster",
  "frame_testnet",
  "fraxtal",
  "fraxtal_sepolia",
  "fuse",
  "gnosis",
  "gold",
  "linea",
  "lisk_sepolia",
  "lyra",
  "lyra_sepolia",
  "mode",
  "mode_sepolia",
  "oasis_sapphire",
  "optimism",
  "op_goerli",
  "op_sepolia",
  "orderly",
  "orderly_sepolia",
  "pgn_sepolia",
  "playground_zitherin",
  "polygon",
  "polygon_zkevm",
  "public_goods_network",
  "scroll",
  "solana",
  "stack",
  "sui",
  "syndicate_frame_chain",
  "tezos",
  "tron",
  "zk_link",
  "zora",
  "zora_goerli",
  "zora_sepolia",
];
