import React from "react";
import { useParams } from "react-router-dom";
import SaveConfigView from "./saveconfig";
import TestConfigView from "./testconfig";
import DeleteConfigView from "./deleteconfig";

export default function IndexConfigView() {
    const { configAction } = useParams();

  return (
    <div>
    {configAction === "save" && <SaveConfigView/>}
    {configAction === "delete" && <DeleteConfigView/>}
    {configAction === "test" && <TestConfigView/>}
    </div>
  );
}
