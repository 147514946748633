/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";
import { AlertDone } from "../../components/request-response";

interface HistoricalData {
  jobid: string;
  indexer: string;
  transformer: string;
  payload: string;
  created_at: string;
  updated_at: string | null;
  completed_at: string | null;
  status: string;
  priority: number;
  tags: string;
  message: string;
  tries: number;
}

export default function RosieHistoricalView() {
  const [configList, setConfigList] = useState([]);
  const [data, setData] = useState<HistoricalData[]>([]);
  const [showExec, setShowExec] = useState(false);
  const [message, setMessage] = useState("The request can't be executed");
  const [status, setStatus] = useState(400);
  const [selectedIndexer, setSelectedIndexer] = useState("");
  const [selectedTransformer, setSelectedTransformer] = useState("");
  const [selectedHours, setSelectedHours] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("failed");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJob, setSelectedJob] = useState<HistoricalData>(null);

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedHours, selectedIndexer, selectedTransformer, selectedStatus]);

  const handleCloseAlert = () => {
    setShowExec(false);
  };

  const fetchConfigs = async () => {
    const options = {
      method: "GET",
      url: `${api_url}configs/all`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request(options);
      setConfigList(response.data);
    } catch (error) {
      console.log(error);
      setConfigList([]);
    }
  };

  const fetchData = async () => {
    const payload = {
      timestamp: new Date(
        new Date().setHours(new Date().getHours() - selectedHours)
      ).toISOString(),
      status: selectedStatus,
      ...(selectedIndexer && { indexer: selectedIndexer }),
      ...(selectedTransformer && { transformer: selectedTransformer }),
    };
    const message = {
      message: {
        data: JSON.stringify(payload),
      },
    };
    const options = {
      method: "POST",
      url: `${api_url}rosie/jobs`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: message,
    };
    await axios
      .request(options)
      .then(function (response) {
        setData(response.data);
        setShowExec(false);
      })
      .catch(function (error) {
        setMessage(`Error: ${error.response?.data || "Unknown error"}`);
        setStatus(error.response.status);
        setShowExec(true);
      });
  };

  const handleHourChange = (hour) => {
    setSelectedHours(hour);
  };

  const handleIndexerChange = (e) => {
    setSelectedIndexer(e.target.value);
    setSelectedTransformer("");
  };

  const handleTransformerChange = (e) => {
    setSelectedTransformer(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleRowClick = (job) => {
    setSelectedJob(job);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateparsed = date.toLocaleString().replace(/\s/g, "").split(",");
    return `${dateparsed[0]}\n${dateparsed[1]}`;
  };

  function transformJobId(jobId) {
    if (typeof jobId !== "string" || jobId.length < 16) {
      return jobId;
    }

    const start = jobId.substring(0, 10);
    const end = jobId.substring(jobId.length - 6);

    return `${start}...${end}`;
  }

  const indexers = Array.from(
    new Set(configList?.map((item) => item.indexer) ?? [])
  );

  const transformers = configList
    .filter((item) => item.indexer === selectedIndexer)
    .map((item) => item.transformer);

  const filteredDataList = data.filter(
    (job) =>
      job.indexer.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.tags.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.jobid.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.transformer.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.payload.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex space-x-2">
          {[1, 3, 5, 8, 24, 48, 72].map((hour) => (
            <button
              key={hour}
              onClick={() => handleHourChange(hour)}
              className={`px-4 py-2 rounded-md ${
                selectedHours === hour
                  ? "bg-green-600 text-white"
                  : "bg-green-200"
              }`}
            >
              {hour}h
            </button>
          ))}
          <button
            key={"reload"}
            onClick={fetchData}
            className={`px-4 py-2 rounded-md bg-green-200`}
          >
            Reload
          </button>
        </div>
        <h1 className="text-3xl font-semibold">Rosie Jobs</h1>
      </div>
      <div className="flex gap-4 mb-4">
        <select
          value={selectedStatus}
          onChange={handleStatusChange}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="failed">Failed</option>
          <option value="enqueued">Enqueued</option>
          <option value="ready">Ready</option>
        </select>
        <select
          value={selectedIndexer}
          onChange={handleIndexerChange}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="">All Indexers</option>
          {indexers.map((indexer) => (
            <option key={indexer} value={indexer}>
              {indexer}
            </option>
          ))}
        </select>
        <select
          value={selectedTransformer}
          onChange={handleTransformerChange}
          className="p-2 border border-gray-300 rounded"
          disabled={!selectedIndexer}
        >
          <option value="">All Transformers</option>
          {transformers.map((transformer) => (
            <option key={transformer} value={transformer}>
              {transformer}
            </option>
          ))}
        </select>
      </div>
      <div className="relative flex items-center">
        <input
          type="text"
          placeholder="Search Config"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-green-200"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-5 w-5 absolute left-3 text-gray-400"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Job Id</th>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Indexer</th>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Transformer</th>
              <th className="py-2 w-2/7 sticky top-0 bg-white">Created</th>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Last update</th>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Status</th>
              <th className="py-2 w-1/7 sticky top-0 bg-white">Tries</th>
            </tr>
          </thead>

          <tbody>
            {filteredDataList.map((job) => (
              <tr
                key={job.jobid}
                onClick={() => handleRowClick(job)}
                className="cursor-pointer hover:bg-green-200 transition-colors"
              >
                <td className="py-2 w-1/9 text-center">
                  {transformJobId(job.jobid)}
                </td>
                <td className="py-2 w-1/9 text-center">{job.indexer}</td>
                <td className="py-2 w-1/9 text-center">{job.transformer}</td>
                <td className="py-2 w-1/9 text-center">
                  {formatTimestamp(job.created_at)}
                </td>
                <td className="py-2 w-1/9 text-center">
                  {formatTimestamp(job.updated_at)}
                </td>
                <td className="py-2 w-1/9 text-center">{job.status}</td>
                <td className="py-2 w-1/9 text-center">{job.tries}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedJob && (
        <div
          className="mt-4 p-4 border border-gray-300 rounded-md"
          style={{
            position: "sticky",
            top: "0",
            maxHeight: "30%",
            overflowY: "auto",
          }}
        >
          <h2 className="text-lg font-semibold mb-2">Selected Job</h2>
          <div style={{ wordBreak: "break-word" }}>
            {" "}
            <p>
              <span className="font-semibold">Job Id:</span> {selectedJob.jobid}
            </p>
            <p>
              <span className="font-semibold">Indexer:</span>{" "}
              {selectedJob.indexer}
            </p>
            <p>
              <span className="font-semibold">Transformer:</span>{" "}
              {selectedJob.transformer}
            </p>
            <p>
              <span className="font-semibold">Payload:</span>{" "}
              {selectedJob.payload}
            </p>
            <p>
              <span className="font-semibold">Created at:</span>{" "}
              {selectedJob.created_at}
            </p>
            <p>
              <span className="font-semibold">Updated at:</span>{" "}
              {selectedJob.updated_at}
            </p>
            {/*'<p>
              <span className="font-semibold">Completed at:</span>{" "}
              {selectedJob.completed_at}
        </p>*/}
            <p>
              <span className="font-semibold">Status:</span>{" "}
              {selectedJob.status}
            </p>
            <p>
              <span className="font-semibold">Priority:</span>{" "}
              {selectedJob.priority}
            </p>
            <p>
              <span className="font-semibold">Tags:</span> {selectedJob.tags}
            </p>
            <p>
              <span className="font-semibold">Message:</span>{" "}
              {selectedJob.message}
            </p>
            <p>
              <span className="font-semibold">Tries:</span> {selectedJob.tries}
            </p>
          </div>
        </div>
      )}
      {showExec && (
        <AlertDone
          message={message}
          status={status}
          open={showExec}
          onClose={handleCloseAlert}
        />
      )}
    </Card>
  );
}
