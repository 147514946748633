import React from "react";
import { useParams } from "react-router-dom";
import OscarNetworks from "./networks";
import NetworksConnections from "./networksConnections";
import LastSeen from "./lastseen";

export default function IndexOscarView() {
  const { oscarAction } = useParams();

  return (
    <div>
      {oscarAction === "networks" && <OscarNetworks/>}
      {oscarAction === "networksconnections" && <NetworksConnections/>}
      {oscarAction === "lastseen" && <LastSeen/>}
    </div>
  );
}
