import React from "react";
import { useParams } from "react-router-dom";
import RosieChartsView from "./charts";
import RosieHistoricalView from "./historical-data";

export default function IndexRosieView() {
    const { rosieAction } = useParams();

  return (
    <div>
    {rosieAction === "charts" && <RosieChartsView/>}
    {rosieAction === "jobs" && <RosieHistoricalView/>}
    </div>
  );
}
