/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { environments, hosts, levels, services } from "./utils";

export default function LiveTailView() {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({
    service: "",
    environment: "",
    host: "",
    tags: "",
  });
  const [selectedLog, setSelectedLog] = useState(null);
  const [fetchPaused, setFetchPaused] = useState(false);
  const [showLevelOptions, setShowLevelOptions] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(new Set());
  const [showServiceOptions, setShowServiceOptions] = useState(false);
  const [selectedServices, setSelectedServices] = useState(new Set());
  const [showEnvironmentOptions, setShowEnvironmentOptions] = useState(false);
  const [selectedEnvironments, setSelectedEnvironments] = useState(new Set());
  const [showHostOptions, setShowHostOptions] = useState(false);
  const [selectedHosts, setSelectedHosts] = useState(new Set());

  const fetchData = async () => {
    try {
      if (!fetchPaused) {
        const payload = {
          services: Array.from(selectedServices),
          levels: Array.from(selectedLevels),
        };
        const message = {
          message: {
            data: JSON.stringify(payload),
          },
        };
        const options = {
          method: "POST",
          url: `https://logger.indexing.co/logger/all`,
          data: message,
        };

        await axios
          .request(options)
          .then(function (response) {
            setLogs(response.data);
          })
          .catch(function (error) {
            console.error("Error fetching logs:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [fetchPaused, selectedServices, selectedLevels]);

  const handleFilterChange = (field, value) => {
    setFilter({ ...filter, [field]: value });
  };

  const handleRowClick = (log) => {
    setSelectedLog(log);
  };

  const toggleFetch = () => {
    setFetchPaused(!fetchPaused);
  };

  const toggleLevelOptions = () => {
    setShowLevelOptions(!showLevelOptions);
  };

  const toggleServiceOptions = () => {
    setShowServiceOptions(!showServiceOptions);
  };

  const toggleEnvironmentOptions = () => {
    setShowEnvironmentOptions(!showEnvironmentOptions);
  };

  const toggleHostOptions = () => {
    setShowHostOptions(!showHostOptions);
  };

  const toggleLevel = (level) => {
    const updatedLevels = new Set(selectedLevels);
    if (updatedLevels.has(level)) {
      updatedLevels.delete(level);
    } else {
      updatedLevels.add(level);
    }
    setSelectedLevels(updatedLevels);
  };

  const toggleService = (service) => {
    const updatedServices = new Set(selectedServices);
    if (updatedServices.has(service)) {
      updatedServices.delete(service);
    } else {
      updatedServices.add(service);
    }
    setSelectedServices(updatedServices);
  };

  const toggleEnvironment = (environment) => {
    const updatedEnvironments = new Set(selectedEnvironments);
    if (updatedEnvironments.has(environment)) {
      updatedEnvironments.delete(environment);
    } else {
      updatedEnvironments.add(environment);
    }
    setSelectedEnvironments(updatedEnvironments);
  };

  const toggleHost = (host) => {
    const updatedHosts = new Set(selectedHosts);
    if (updatedHosts.has(host)) {
      updatedHosts.delete(host);
    } else {
      updatedHosts.add(host);
    }
    setSelectedHosts(updatedHosts);
  };

  const isSelectedLevel = (level) => {
    return selectedLevels.has(level);
  };

  const isSelectedService = (service) => {
    return selectedServices.has(service);
  };

  const isSelectedEnvironment = (environment) => {
    return selectedEnvironments.has(environment);
  };

  const isSelectedHost = (host) => {
    return selectedHosts.has(host);
  };

  const formatTags = (tagsString) => {
    if (!tagsString) return "";
    try {
      const tags = JSON.parse(tagsString);
      const { feedType, indexer, transformer } = tags;
      if (
        feedType === undefined ||
        indexer === undefined ||
        transformer === undefined
      ) {
        return "";
      }
      return `${feedType}-${indexer}-${transformer}`;
    } catch (error) {
      return "";
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateparsed = date.toLocaleString().replace(/\s/g, "").split(",");
    return `${dateparsed[0]}\n${dateparsed[1]}`;
  };

  const filteredLogs = logs.filter((log) => {
    for (const key in filter) {
      if (
        filter[key] &&
        !log[key].toLowerCase().includes(filter[key].toLowerCase())
      ) {
        return false;
      }
    }
    if (
      selectedLevels.size > 0 &&
      !selectedLevels.has(log.level.toLowerCase())
    ) {
      return false;
    }
    if (
      selectedServices.size > 0 &&
      !selectedServices.has(log.service.toLowerCase())
    ) {
      return false;
    }
    if (
      selectedEnvironments.size > 0 &&
      !selectedEnvironments.has(log.environment.toLowerCase())
    ) {
      return false;
    }
    if (selectedHosts.size > 0 && !selectedHosts.has(log.host.toLowerCase())) {
      return false;
    }
    return true;
  });

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl shadow-blue-gray-900/5 overflow-auto">
      <div className="flex justify-between items-center">
        <button
          onClick={toggleFetch}
          className="px-4 py-2 bg-green-300 text-white rounded-md hover:bg-green-400 transition-colors"
        >
          {fetchPaused ? "Resume Records" : "Pause Records"}
        </button>
        <h1 className="text-3xl font-semibold my-6 text-center">Live Tail</h1>
      </div>
      <div className="flex">
        <div className="relative flex-grow mr-2">
          <button
            onClick={toggleServiceOptions}
            className="flex items-center justify-between w-full px-2 py-1 border rounded-md bg-white hover:border-green-300 focus:border-green-400 transition-colors"
          >
            <span>Service</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 8.293a1 1 0 011.414 0L10 9.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414zM10 4a1 1 0 100 2 1 1 0 000-2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {showServiceOptions && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
              {services.map((service) => (
                <label
                  key={service}
                  className="block px-4 py-2 cursor-pointer hover:bg-green-300 transition-colors"
                >
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight"
                    checked={isSelectedService(service)}
                    onChange={() => toggleService(service)}
                  />
                  {service}
                </label>
              ))}
            </div>
          )}
        </div>
        <div className="relative flex-grow mr-2">
          <button
            onClick={toggleEnvironmentOptions}
            className="flex items-center justify-between w-full px-2 py-1 border rounded-md bg-white hover:border-green-300 focus:border-green-400 transition-colors"
          >
            <span>Environment</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 8.293a1 1 0 011.414 0L10 9.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414zM10 4a1 1 0 100 2 1 1 0 000-2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {showEnvironmentOptions && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
              {environments.map((enviroment) => (
                <label
                  key={enviroment}
                  className="block px-4 py-2 cursor-pointer hover:bg-green-300 transition-colors"
                >
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight"
                    checked={isSelectedEnvironment(enviroment)}
                    onChange={() => toggleEnvironment(enviroment)}
                  />
                  {enviroment}
                </label>
              ))}
            </div>
          )}
        </div>
        <div className="relative flex-grow mr-2">
          <button
            onClick={toggleHostOptions}
            className="flex items-center justify-between w-full px-2 py-1 border rounded-md bg-white hover:border-green-300 focus:border-green-400 transition-colors"
          >
            <span>Host</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 8.293a1 1 0 011.414 0L10 9.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414zM10 4a1 1 0 100 2 1 1 0 000-2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {showHostOptions && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
              {hosts.map((host) => (
                <label
                  key={host}
                  className="block px-4 py-2 cursor-pointer hover:bg-green-300 transition-colors"
                >
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight"
                    checked={isSelectedHost(host)}
                    onChange={() => toggleHost(host)}
                  />
                  {host}
                </label>
              ))}
            </div>
          )}
        </div>
        <input
          type="text"
          className="flex-grow mr-2 px-2 py-1 border rounded-md hover:border-green-300 focus:border-green-400 transition-colors"
          placeholder="Tags"
          value={filter.tags}
          onChange={(e) => handleFilterChange("tags", e.target.value)}
        />
        <div className="relative flex-grow mr-2">
          <button
            onClick={toggleLevelOptions}
            className="flex items-center justify-between w-full px-2 py-1 border rounded-md bg-white hover:border-green-300 focus:border-green-400 transition-colors"
          >
            <span>Level</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 8.293a1 1 0 011.414 0L10 9.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414zM10 4a1 1 0 100 2 1 1 0 000-2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {showLevelOptions && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
              {levels.map((level) => (
                <label
                  key={level}
                  className="block px-4 py-2 cursor-pointer hover:bg-green-300 transition-colors"
                >
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight"
                    checked={isSelectedLevel(level)}
                    onChange={() => toggleLevel(level)}
                  />
                  {level}
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-2 w-1/7">Service</th>
              <th className="py-2 w-1/7">Environment</th>
              <th className="py-2 w-1/7">Host</th>
              <th className="py-2 w-2/7">Message</th>
              <th className="py-2 w-1/7">Tags</th>
              <th className="py-2 w-1/7">Level</th>
              <th className="py-2 w-1/7">Time</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log) => (
              <tr
                key={log.id}
                onClick={() => handleRowClick(log)}
                className="cursor-pointer hover:bg-green-200 transition-colors"
              >
                <td className="py-2 w-1/9 text-center">{log.service}</td>
                <td className="py-2 w-1/9 text-center">{log.environment}</td>
                <td className="py-2 w-1/9 text-center">{log.host}</td>
                <td className="py-2 w-2/9 text-center overflow-hidden overflow-ellipsis">
                  {log.message.slice(0, 40)}
                </td>
                <td className="py-2 w-2/9 text-center overflow-hidden overflow-ellipsis">
                  {formatTags(log.tags).slice(0, 35)}
                </td>
                <td className="py-2 w-1/9 text-center">{log.level}</td>
                <td className="py-2 w-1/9 text-center">
                  {formatTimestamp(log.timestamp)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedLog && (
        <div
          className="mt-4 p-4 border border-gray-300 rounded-md"
          style={{
            position: "sticky",
            top: "0",
            maxHeight: "30%",
            overflowY: "auto",
          }}
        >
          <h2 className="text-lg font-semibold mb-2">Selected Log</h2>
          <div>
            <p>
              <span className="font-semibold">Service:</span>{" "}
              {selectedLog.service}
            </p>
            <p>
              <span className="font-semibold">Environment:</span>{" "}
              {selectedLog.environment}
            </p>
            <p>
              <span className="font-semibold">Host:</span> {selectedLog.host}
            </p>
            <p>
              <span className="font-semibold">Message:</span>{" "}
              {selectedLog.message}
            </p>
            <p>
              <span className="font-semibold">Tags:</span> {selectedLog.tags}
            </p>
            <p>
              <span className="font-semibold">Level:</span> {selectedLog.level}
            </p>
            <p>
              <span className="font-semibold">Timestamp:</span>{" "}
              {selectedLog.timestamp}
            </p>
          </div>
        </div>
      )}
    </Card>
  );
}
