import React from "react";
import { useParams } from "react-router-dom";
import SaveCustomScriptView from "./saveCustomScript";
import SaveFeatureFlagsView from "./saveFeatureFlag";

export default function IndexUtilsView() {
    const { utilsAction } = useParams();

  return (
    <div>
    {utilsAction === "addscript" && <SaveCustomScriptView/>}
    {utilsAction === "flags" && <SaveFeatureFlagsView/>}

    </div>
  );
}
