import React from "react";
import { useParams } from "react-router-dom";
import RpcMetricsView from "./metrics";

export default function IndexTestingView() {
    const { testingAction } = useParams();

  return (
    <div>
    {testingAction === "metrics" && <RpcMetricsView/>}

    </div>
  );
}
